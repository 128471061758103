export enum DriverWork {
  // resources keys
  BW = 'BLANKET_WRAPPED',
  DA = 'ASSIST',
  DAC = 'ASSIST_COUNT',
  DAT = 'ASSIST_TAILGATE',
  DC = 'COUNT',
  DL = 'LOADING',
  DU = 'UNLOADING',
  LC = 'LOAD_COUNT',
  LR = 'LABOR_REQUIRED_LUMPER_SERVICE_AVAILABLE',
  LRN = 'LABOR_REQUIRED_NO_LUMPER_AVAILABLE',
  MLR = 'MISC_LABOR_REQUIRED_CALL_C_H_ROBINSON_FOR_DETAILS',
  NT = 'NO_TOUCH',
  PB = 'PALLET_BREAKDOWN',
  TG = 'TAILGATE',
  TMP = 'CHECK_TEMPERATURE',

  // enum values
  BlanketWrapped = 'BW',
  Assist = 'DA',
  AssistCount = 'DAC',
  AssistTailgate = 'DAT',
  Count = 'DC',
  Loading = 'DL',
  Unloading = 'DU',
  LoadCount = 'LC',
  LaborRequiredLumperServiceAvailable = 'LR',
  LaborRequiredNoLumperAvailable = 'LRN',
  MiscLaborRequiredCallCHRobinsonForDetails = 'MLR',
  NoTouch = 'NT',
  PalletBreakdown = 'PB',
  Tailgate = 'TG',
  CheckTemperature = 'TMP'
}
